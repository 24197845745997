




































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { recoveryHardBounce } from '@/api/consoleApi/recipients'
import { getReportHardBounce } from '@/api/consoleApi/reports'
import VsChart from '@/components/VsChart/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import html2pdf from 'html2pdf.js'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import { IContact } from '@/api/consoleApi/types'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { createSuppression } from '@/api/consoleApi/suppressions'
import axios from 'axios'

@Component({
    name: 'ContactDeliverability',
    components: {
        VsChart,
        VsLoader,
        VsFullTopBarLayout,
        VsContainer,
        VsDropdownButton,
        VsSectionHeader,
        VsConfirm,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) contact!: IContact
    @Prop({ default: false, required: true }) emailSuppression!: any
    public loading = false
    private result: {
        result: 'deliverable' | 'undeliverable' | 'risky' | 'unknown'
        reason: 'invalid_email' | 'invalid_domain'| 'rejected_email' | 'accepted_email' | 'low_quality' | 'low_deliverability' | 'no_connect' | 'timeout' | 'invalid_smtp' | 'unavailable_smtp' | 'unexpected_error'
        role: boolean
        disposable: boolean
        free: boolean
        senderIndex: number
    } | null = null

    $refs: any

    get listId () {
        return this.$route?.params?.listId
    }

    get contactId () {
        return this.$route?.params?.contactId
    }

    get senderIndex () {
        return this.result?.senderIndex || 0
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get progressColor () {
        if (this.senderIndex > 0.55) {
            return '#00BF9C'
        } else if (this.senderIndex < 0.55 && this.senderIndex >= 0.20) {
            return '#E77C44'
        } else if (this.senderIndex < 0.20) {
            return '#EC484B'
        }
    }

    get chartOptions (): any {
        return {
            series: [
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 1,
                    center: ['50%', '90%'],
                    radius: '150%',
                    splitNumber: 10,
                    itemStyle: {
                        color: '#FF6E76',
                    },
                    progress: {
                        show: true,
                        roundCap: false,
                        width: 50,
                        color: '#000',
                        itemStyle: {
                            color: this.progressColor,
                        },
                    },
                    pointer: false,
                    axisLine: {
                        roundCap: false,
                        width: 200,
                        lineStyle: {
                            width: 50,
                        },
                    },
                    axisTick: false,
                    splitLine: false,
                    axisLabel: false,
                    title: {
                        show: false,
                    },
                    detail: {
                        backgroundColor: '#fff',
                        borderColor: '#999',
                        width: '60%',
                        lineHeight: 14,
                        offsetCenter: [0, -12],
                        valueAnimation: true,
                        fontSize: 36,
                        fontWeight: 700,
                        color: '#60686A',
                    },
                    data: [
                        {
                            value: this.senderIndex,
                        },
                    ],
                },
            ],
        }
    }

    async mounted () {
        this.loading = true
        await this.getReport()
        this.loading = false
    }

    @Watch('contact', { deep: true })
    private async getReport () {
        try {
            const email = get(this.contact, 'email_address', '')
            if (email) {
                const resp = await getReportHardBounce(email)
                this.result = resp.data
            }
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la richiesta del report',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    private async resetContact () {
        let message = ''
        let errorTimeout = 3000
        this.loading = true
        try {
            const resp = await recoveryHardBounce(this.listId, this.contactId)
            if (resp.data.error) {
                message = resp.data.message
                errorTimeout = 10000
                throw new Error()
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Indirizzo email ripristinato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('form-saved')
        } catch (e) {
            this.$root.$vsToast({
                timeout: errorTimeout,
                heading: 'Tentativo di ripristino fallito',
                message,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    async downloadReport () {
        this.loading = true
        this.$refs.printContent.style.width = '800px'
        this.$refs.printContent.style.padding = '32px'
        this.$refs.chart.chartResizeHandler()
        const opt = {
            filename: `recovery_email_report.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                windowWidth: 800,
            },
            enableLinks: false,
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
            },
        }
        setTimeout(async () => {
            await html2pdf().from(this.$refs.printContent).set(opt).save()
            this.$refs.printContent.style.width = 'auto'
            this.$refs.printContent.style.padding = '0px'
            this.$refs.chart.chartResizeHandler()
            this.loading = false
        }, 1000)
    }

    private async suppress (email: string) {
        try {
            await this.$refs.addEmailSuppressionConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await createSuppression({ email_address: email })
            this.$emit('form-saved')
            this.$root.$vsToast({
                heading: 'Indirizzo email aggiunto con successo alla lista di soppressione',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            console.log(e)
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    if (e.response?.data?.message?.email_address && e.response.data.message.email_address[0].includes('already suppressed')) {
                        message = this.$t('lists.suppression.addSuppressionModal.errorEmail').toString()
                    }
                }
            }
            this.$root.$vsToast({
                heading: 'Errore durante l\'inserimento dell\'indirizzo email alla lista di soppressione',
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }
}
